@import "src/variables";

.clickpass-tabs {
  height: 100%;
  background: $shade1;

  .ant-tabs-ink-bar {
    background: none;
  }

  .ant-tabs-nav {
    margin: 0;
    background: $shade5;
  }

  .ant-tabs-tab {
    padding: 12px 24px;
    color: $gray9;

    &:hover {
      background: $shade3;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab-active {
    color: black;
    background: $shade3;

    & .ant-tabs-tab-btn {
      color: black;
      font-weight: bold;
    }
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-content {
    height: 100%;
    max-height: 100%;
  }

  .ant-tabs-tabpane {
    height: 100%;
    max-height: 100%;
  }
}
