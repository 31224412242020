@import "src/variables";

.Header {
  background-color: $shade5;
  line-height: 39px;
  color: $shade9;
  display: grid;
  grid-template-columns: 1fr auto;

  b.title {
    margin-left: 12px;
    float: left;
    display: inline-block;
  }

  span {
    float: right;
    margin-right: 12px;
  }

  a {
    color: $shade9;
    text-decoration: underline;
    margin-left: 12px;

    &:hover {
      color: black;
    }
  }
}
