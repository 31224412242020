.ApplicationLayout {
  height: 100%;
  min-height: 0;
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-columns: 235px 1fr;
  place-items: stretch;

  &-header {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &-menu {
    overflow-y: auto;
  }

  &-content {
    min-height: 0;
    overflow: auto;
  }
}
