.BaseCalendarProductFormModal {
  &-error {
    color: red;
  }

  &-categoryLabel,
  &-durationLabel,
  &-photosLabel {
    padding-bottom: 8px;
  }

  &-defaultTimeSection {
    display: flex;
    gap: 10px;
  }
}
