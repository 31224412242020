.Balance {
  padding: 30px;

  &-balance {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom: 30px;
  }

  &-form {
    margin-top: 10px;
  }

  &-numberInput {
    width: 200px;
  }
}
